<template>
  <div class="loading">
    <img src="@/assets/img/loading.svg" />
  </div>
</template>

<script>
import RedirectService from "@/services/resources/RedirectService";
const service = RedirectService.build();

export default {
  name: "Redirect",
  methods: {
    redirect(token) {
      let data = {
        id: token,
      };

      service
        .read(data)
        .then((response) => {
          window.location.href = response.link;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  created() {
    let token = this.$route.params.token;
    this.redirect(token);
  },
};
</script>

<style lang="scss">
.loading {
  width: 100%;
  height: 100%;
  background: #fff;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 9;

  img {
    width: 100px;
  }
}
</style>
